/* General App Styles */
.app {
    min-height: 100vh;
    transition: all 0.3s ease;
    font-family: 'Roboto', sans-serif;
    background-color: #121212; /* Dark background for the app */
    color: #e1e1e1; /* Light text color */
  }
  
  /* Header */
  .header {
    background-color: #1c1c1c; /* Darker background for header */
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }
  .header h1 {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .menu-toggle {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .desktop-toggles {
    display: none;
  }
  @media (min-width: 768px) {
    .menu-toggle {
      display: none;
    }
    .desktop-toggles {
      display: flex;
      gap: 1.25rem;
    }
    .desktop-toggles button {
      background: none;
      border: none;
      color: #ffffff;
      cursor: pointer;
      font-size: 1rem;
      transition: color 0.3s ease;
    }
    .desktop-toggles button:hover {
      color: #4a90e2;
    }
  }
  
  /* Mobile Menu */
  .mobile-menu {
    background-color: #1f1f1f; /* Dark background */
    padding: 1.25rem;
    border-top: 2px solid #4a5568;
  }
  .mobile-menu h2 {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .mobile-menu select {
    width: 100%;
    padding: 0.75rem;
    background-color: #121212;
    color: #ffffff;
    border: 1px solid #4a5568;
    border-radius: 0.375rem;
    transition: border-color 0.3s ease;
  }
  .mobile-menu select:hover {
    border-color: #4a90e2;
  }
  .mobile-toggles {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .mobile-toggles button {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    text-align: left;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  .mobile-toggles button:hover {
    color: #4a90e2;
  }
  @media (min-width: 768px) {
    .mobile-menu {
      display: none;
    }
  }
  
  /* Main Content */
  .main {
    max-width: 70rem;
    margin: 0 auto;
    padding: 2rem;
    background: #181818; /* Darker background */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
  .main h2 {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: #ffffff; /* Light color for headings */
  }
  .article-list {
    list-style: none;
    padding: 0;
  }
  .article-item {
    background-color: #222222; /* Dark background for items */
    padding: 1.25rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  .article-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    transform: translateY(-5px);
  }
  .article-item a {
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  .article-item a:hover {
    color: #4a90e2;
  }
  .article-meta {
    font-size: 0.875rem;
    color: #9e9e9e; /* Lighter text for meta */
    margin-top: 0.25rem;
  }
  .article-meta button {
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
    font-size: 0.875rem;
    transition: color 0.3s ease;
  }
  .article-meta button:hover {
    color: #4a90e2;
  }
  
  /* Load More */
  .load-more {
    text-align: center;
    margin-top: 2rem;
  }
  .load-more button {
    background-color: #4a90e2;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  .load-more button:hover {
    background-color: #357ae8;
  }


  /* Styles for Login Page */
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    background: #121212; /* Dark background for login */
  }

  .logo {
    height: 50px;
  }
  
 
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .profile-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background: #222222;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .profile-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #ffffff;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #4a90e2;
    margin-bottom: 10px;
  }
  
  .profile-info {
    font-size: 1rem;
    color: #e1e1e1;
    margin: 5px 0;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
  
  .logout-button {
    background-color: #ff4c4c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #d83434;
  }
  
  .delete-button {
    background-color: #ff4c4c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #d83434;
  }
  
  .status-message {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .status-message.error {
    color: #ff4c4c;
  }
  
  .status-message.success {
    color: #4a90e2; /* Blue to match the profile image border */
  }
  
  .error-message {
    color: #ff4c4c;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 20px;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #222222; /* Match the profile-container background */
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #e1e1e1; /* Light gray to fit the theme */
    border-top: 4px solid #4a90e2; /* Blue to match the profile image border */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
    