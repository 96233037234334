body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Light Mode (default) */
body {
  background-color: #ffffff;
  color: #000000;
}
body a {
  color: #000000;
}
body a:hover {
  color: #808080;
}

/* Dark Mode */
body.dark-mode {
  background-color: #1a202c;
  color: #e2e8f0;
}
body.dark-mode a {
  color: #63b3ed;
}
body.dark-mode a:hover {
  color: #90cdf4;
}